import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Alert, Button, FormGroup, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  removeAllCookies,
  setCookie,
  SPECTRUM_MEDICAL,
  SPECTRUM_MEDICAL_KIOSK,
} from 'qi-scripts';
import { login } from '../../services/client';

import './Login.scss';

export const LoginView = () => {
  const { returnUrl } = useParams<any>();
  const history = useHistory();
  const { t } = useTranslation();

  const [loginError, setLoginError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    // check if cookie is enabled
    if (!navigator.cookieEnabled) {
      setLoginError(true);
      setErrorMessage(t('You need to enable cookies to use this app'));
    }
  });

  function validateForm() {
    return username.length > 0 && password.length > 0;
  }

  const submitLogin = async () => {
    return login(username, password);
  };

  function handleSubmit(event: any) {
    event.preventDefault();

    // remove old cookies
    removeAllCookies();

    submitLogin()
      .then((response: any) => {
        const { data } = response;
        if (!data.token) {
          setLoginError(true);
          setErrorMessage(t('An error occurred'));
        } else if (data.token) {
          setCookie(SPECTRUM_MEDICAL, data.token);
          if (data.kioskToken) {
            setCookie(SPECTRUM_MEDICAL_KIOSK, data.kioskToken);
          }
          if (returnUrl) {
            window.open(atob(returnUrl), '_self');
          } else {
            // console.log('nav to splash screen...');
            history.push('/apps');
          }
        }
      })
      .catch((error: any) => {
        // console.log('error: ', error);
        if (error?.status === 401) {
          setLoginError(true);
          setErrorMessage(t('An error occurred'));
        }
      });
  }

  return (
    <div className="login">
      <div className="logo">
        <div className="spectrum_icon">
          <img
            src="/spectrum-small-icon_white.png"
            alt="Spectrum Medical"
            width="20px"
          />
        </div>
        {/* eslint-disable-next-line i18next/no-literal-string */}
        <div className="login_title">{t('Quantum Informatics')}</div>
      </div>
      <form onSubmit={handleSubmit}>
        <FormGroup className="login_form_group">
          <TextField
            id="username"
            variant="standard"
            label={t('Username')}
            autoComplete="username"
            // helperText="Enter your Vision username"
            onChange={(e) => setUsername(e.target.value)}
            // error={loginError}
            autoFocus
          />
          <TextField
            id="password"
            variant="standard"
            label={t('Password')}
            type="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            // error={loginError}
          />
          <Button variant="outlined" disabled={!validateForm()} type="submit">
            {t('Login')}
          </Button>
        </FormGroup>
      </form>
      {loginError && (
        <Alert severity="error" variant="outlined" className="login_error">
          {errorMessage}
        </Alert>
      )}
    </div>
  );
};

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

// import i18n (needs to be bundled)
import './i18n/i18n';

const loadJSON = (callback: any) => {
  const xobj = new XMLHttpRequest();
  xobj.overrideMimeType('application/json');
  xobj.open('GET', `${window.location.origin}/config.json`, true);
  xobj.onreadystatechange = () => {
    if (xobj.readyState === 4 && xobj.status === 200) {
      // Required use of an anonymous callback as .open will NOT return a value but simply returns undefined in asynchronous mode
      callback(xobj.responseText);
    }
  };
  xobj.send(null);
};

function init() {
  loadJSON((response: any) => {
    // Parse JSON string into object
    const actualJSON = JSON.parse(response);

    (window as any).runtimeConfig = {
      ...actualJSON,
    };

    const root = ReactDOM.createRoot(
      document.getElementById('root') as HTMLElement,
    );

    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
    );

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
  });
}

init();

import React, { Suspense, FC } from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { SplashScreen } from './components/Home/Home';
import { LoginView } from './components/Login/Login';
import './App.scss';

const theme = createTheme();

const RenderView: FC<any> = (/* { loading, isLatestVersion, refreshCacheAndReload } */) => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Route exact path="/auth/:returnUrl?" component={LoginView} />
        <Route exact path="/apps" component={SplashScreen} />
        <Route exact path="/" component={() => <Redirect to="/apps" />} />
      </Router>
    </ThemeProvider>
  );
};

function App() {
  return (
    <Suspense fallback="loading...">
      <RenderView />
    </Suspense>
  );
}

export default App;
